import { Component, EventEmitter, HostBinding, Output } from '@angular/core';

@Component({
  selector: 'nem-drawer',
  templateUrl: './nem-drawer.comp.html',
  styleUrls: ['./nem-drawer.comp.scss']
})
export class NemDrawerComponent {
  isCollapsed = false;

  @Output() isCollapsedChange = new EventEmitter();

  @HostBinding('class.opened') get openedClass() {
    return !this.isCollapsed;
  }

  toggleDrawer() {
    this.isCollapsed = !this.isCollapsed;
    this.isCollapsedChange.emit(true);
  }
}
