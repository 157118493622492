import { Renderer2 } from '@angular/core';

export function fixScroll(evt: 'onshow' | 'onhide', renderer: Renderer2, name: string) {
  if (evt === 'onshow') {
    renderer.addClass(document.body, 'fix-scroll-' + name);
  }

  if (evt === 'onhide') {
    renderer.removeClass(document.body, 'fix-scroll-' + name);
  }
}

export function generateId(): string {
  let id = '';
  const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }

  return id;
}

export function diffObjects<T extends Record<string, any>>(obj1: T, obj2: T): Partial<T> {
  const diff: Partial<T> = {};

  // Loop through the keys of the first object
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        diff[key] = obj2.hasOwnProperty(key) ? obj2[key] : undefined;
      }
    }
  }

  // Loop through the keys of the second object to catch any keys not in obj1
  for (const key in obj2) {
    if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
      diff[key] = obj2[key];
    }
  }

  return diff;
}
