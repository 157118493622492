<ul class="text-xs bg-white" [ngClass]="filtersListClass" [formGroup]="filterService.query_fg">
  <ng-content></ng-content>

  <li *ngIf="filtersToShow.includes('regions')">
    <h5 class="mb-1 font-semibold">
      Regions:
      <i
        class="pi pi-info-circle"
        [pTooltip]="netInterconnectorsInfo"
        *ngIf="showNetInterconnectorsInfo"
        [escape]="false"></i>
    </h5>
    <ul class="space-y-6 lg:space-y-2">
      <li class="block text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
        <p-multiSelect
          (onPanelHide)="fixbodyscroll('onhide', 'regions')"
          (onPanelShow)="fixbodyscroll('onshow', 'regions')"
          formControlName="regions"
          [filter]="false"
          [options]="regions"
          appendTo="body"
          defaultLabel="Select a Region"
          display="chip"
          optionLabel="name"
          optionValue="id"
          styleClass=" w-[12rem]">
        </p-multiSelect>
      </li>
    </ul>
  </li>

  <li *ngIf="filtersToShow.includes('single_region')">
    <h5 class="mb-1 font-semibold">Region</h5>
    <ul class="space-y-6 lg:space-y-2">
      <li class="block text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
        <p-dropdown
          (onHide)="fixbodyscroll('onhide', 'region')"
          (onShow)="fixbodyscroll('onshow', 'region')"
          formControlName="region"
          [filter]="false"
          [options]="regions"
          appendTo="body"
          defaultLabel="Select a Region"
          display="chip"
          optionLabel="name"
          optionValue="id"
          styleClass=" w-[12rem]">
        </p-dropdown>
      </li>
    </ul>
  </li>

  <ul class="flex gap-2" *ngIf="filtersToShow.includes('advanced')" [ngClass]="advancedClass">
    <li>
      <h5 class="mb-1 font-semibold">Primary source/technology type</h5>
      <ul class="space-y-6 lg:space-y-2 border-slate-100 dark:border-slate-700">
        <li class="block text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
          <p-multiSelect
            (onPanelHide)="fixbodyscroll('onhide', 'fuelSources')"
            (onPanelShow)="fixbodyscroll('onshow', 'fuelSources')"
            formControlName="fuel_sources"
            [filter]="false"
            [options]="$any(filterService.fuelSourcesOptions$ | async)"
            appendTo="body"
            defaultLabel="Select a Fuel source"
            display="chip"
            styleClass="w-[14rem]">
          </p-multiSelect>
        </li>
      </ul>
    </li>
    <ng-container *ngIf="filtersToShow.includes('advanced:full')">
      <li>
        <h5 class="mb-1 flex font-semibold">
          <span class="flex-1">Stations</span>
          <ng-container *ngIf="stationDates$ | async as dates">
            <span class="text-indigo-400" [escape]="false" [pTooltip]="stationsDateInfo">
              {{ dates.min | date : 'dd/MM/yyyy' }} - {{ dates.max | date : 'dd/MM/yyyy' }}
            </span>
          </ng-container>
        </h5>
        <ul class="space-y-6 lg:space-y-2 border-slate-100 dark:border-slate-700">
          <li class="block text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
            <p-multiSelect
              (onPanelHide)="fixbodyscroll('onhide', 'stations')"
              (onPanelShow)="fixbodyscroll('onshow', 'stations')"
              formControlName="stations"
              [filter]="true"
              [options]="$any(stationOptions$ | async)"
              appendTo="body"
              dataKey="name"
              defaultLabel="Select stations"
              display="chip"
              optionLabel="name"
              styleClass="w-[18rem]">
              <ng-template let-station pTemplate="item">
                <div class="flex flex-col gap-2 w-full">
                  <div class="flex items-center gap-2">
                    <div class="font-bold text-sm text-slate-600">
                      {{ station.name }}
                    </div>
                    <div class="ml-auto text-xs border text-indigo-400 border-indigo-200 px-2 py-1 rounded-md">
                      {{ station.last_changed | date : 'dd/MM/yyyy' }}
                    </div>
                  </div>

                  <div class="flex items-center gap-2">
                    <div class="flex gap-2">
                      <span
                        class="bg-indigo-50 text-xs text-indigo-600 border border-indigo-100 px-2 py-1 rounded-md"
                        *ngFor="let duid of station.duids">
                        {{ duid.name }}
                      </span>
                    </div>
                    <div class="ml-auto text-xs">
                      <p-selectButton
                        (onChange)="stationsOnChange($event)"
                        (onOptionClick)="stationsOnChange($event)"
                        *ngIf="station.duids.length > 1"
                        [(ngModel)]="station.grouped"
                        [ngModelOptions]="{ standalone: true }"
                        [disabled]="!isSelected(station.name)"
                        [options]="[
                          { label: 'Grouped', value: true },
                          { label: 'Individual', value: false }
                        ]"
                        optionLabel="label"
                        optionValue="value"></p-selectButton>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-multiSelect>
          </li>
        </ul>
      </li>

      <li [ngClass]="{ 'mt-4': isHorizontalLayout, 'mb-1': !isHorizontalLayout }">
        <ul class="mt-4 space-y-6 lg:space-y-2 border-slate-100 dark:border-slate-700">
          <li class="flex align-items-center gap-1">
            <p-checkbox formControlName="aggr_by_fuel_sources" [binary]="true" inputId="aggrByFuelSources">
            </p-checkbox>
            <label for="aggrByFuelSources">Aggregate by primary source</label>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>

  <li *ngIf="filtersToShow.includes('interconnectors')">
    <h5 class="mb-1 font-semibold">
      Interconnectors:
      <i
        class="pi pi-info-circle"
        [pTooltip]="interconnectorsInfo"
        [escape]="false"
        *ngIf="showInterconnectorsInfo"></i>
    </h5>
    <ul class="space-y-6 lg:space-y-2">
      <li class="block text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
        <p-multiSelect
          (onPanelHide)="fixbodyscroll('onhide', 'interconnectors')"
          (onPanelShow)="fixbodyscroll('onshow', 'interconnectors')"
          formControlName="interconnectors"
          [filter]="false"
          [options]="interConnectors"
          appendTo="body"
          defaultLabel="Select an Interconnector"
          display="chip"
          optionLabel="name"
          optionValue="id"
          styleClass="w-[12rem]">
        </p-multiSelect>
      </li>
    </ul>
  </li>

  <li *ngIf="filtersToShow.includes('range')">
    <h5 class="mb-1 font-semibold">
      Date range:
      <i *ngIf="showRangeInfo" [escape]="false" [pTooltip]="dateRangeInfo" class="pi pi-info-circle"></i>
    </h5>
    <ul class="space-y-6 lg:space-y-2">
      <li class="flex gap-3 text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
        <p-calendar
          formControlName="range_from"
          appendTo="body"
          dataType="string"
          dateFormat="dd/mm/yy"
          inputId="from"
          styleClass="nem-calender w-[8rem]	 "></p-calendar>

        <p-calendar
          formControlName="range_to"
          appendTo="body"
          dataType="string"
          dateFormat="dd/mm/yy"
          inputId="to"
          styleClass="nem-calender w-[8rem]"></p-calendar>
      </li>
    </ul>
  </li>

  <li *ngIf="filtersToShow.includes('frequency')">
    <h5 class="mb-1 font-semibold">Frequency:</h5>
    <ul class="space-y-6 lg:space-y-2">
      <li class="block text-slate-700 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300">
        <p-dropdown
          (onHide)="fixbodyscroll('onhide', 'frequency')"
          (onShow)="fixbodyscroll('onshow', 'frequency')"
          formControlName="frequency"
          [options]="dataFrequency"
          [showClear]="false"
          appendTo="body"
          class="leading-1"
          optionLabel="name"
          optionValue="id"
          placeholder="Select a frequency"
          styleClass="w-[12rem] "></p-dropdown>
      </li>
    </ul>
  </li>

  <li class="mt-5">
    <button
      (click)="filtersSubmit($event)"
      [disabled]="false"
      [loading]="loading"
      class="p-button w-full"
      label="Load data"
      pButton></button>
  </li>
</ul>
