import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';

import { ErrorInterceptor } from '@services/error-interceptor';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';

import { Amplify } from 'aws-amplify';
import { environment } from 'src/environments/environment';

import { AuthInterceptor } from '@services/auth/auth-interceptor';
import { AuthService } from '@services/auth/auth.service';
import { AdminGuard } from '@services/guards/admin.guard';
import { AuthGuard } from '@services/guards/auth.guard';
import { SharedModule } from './shared/shared.module';

import { I18n } from 'aws-amplify';

I18n.putVocabulariesForLanguage('en', {
  'Given Name': 'Organisation',
  'Enter your Given Name': 'Add your Organisation'
});

Amplify.configure({
  Auth: environment.cognito
});

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./views/views.module').then(m => m.ViewsNemDashboardModule)
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    MessagesModule
  ],
  providers: [
    MessageService,
    AuthGuard,
    AdminGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (messageService: MessageService) => new ErrorInterceptor(messageService),
      deps: [MessageService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'en-AU' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
