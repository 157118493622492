import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CHART_TYPES } from '@constants/payloads';

@Component({
  selector: 'nem-chart-toolbar',
  templateUrl: 'nem-chart-toolbar.comp.html'
})
export class NemChartToolbarComponent {
  @Output() toggleTableViewChange = new EventEmitter();

  @Output() exportToCSVChange = new EventEmitter();

  @Output() dialogVisibleChange = new EventEmitter();

  @Input() chartTypeValue: string | null = null;

  @Output() chartTypeValueChange = new EventEmitter();

  @Output() focusModeChange = new EventEmitter();

  chartTypeOptions = CHART_TYPES;
}
