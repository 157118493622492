<div
  *ngIf="(authenticated$ | async) && topSlot"
  class="flex align-center z-20 lg:inset-x-0 lg:top-[4rem] bg-white border-b-neutral-200 border-b">
  <nav id="nav" class="w-full relative lg:py-2 lg:px-6">
    <ng-content select="[left]"></ng-content>
  </nav>
</div>

<ng-container *ngIf="contentWithScroll; else baseTemplate">
  <p-scrollPanel [style]="panelStyle" styleClass="custombar w-full">
    <ng-container *ngTemplateOutlet="baseTemplate"></ng-container>
  </p-scrollPanel>
</ng-container>

<ng-template #baseTemplate>
  <div [ngClass]="contentClass">
    <ng-content></ng-content>
  </div>
</ng-template>
