import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { NgApexchartsModule } from 'ng-apexcharts';

import { NumberFormatterPipe } from './pipes/number-formatter';
import { TruncatePipe } from './pipes/truncate';

import { NemChartComponent } from './components/nem-chart/nem-chart.comp';

import { MainLayoutComponent } from '@components/layouts/main-layout.comp';
import { MultiSelectModule } from 'primeng/multiselect';
import { NemChartMainComponent } from './components/layout/nem-chart-main.comp';
import { NemPlotComponent } from './components/layout/nem-plot.comp';
import { NemShimmerComponent } from './components/layout/nem-shimmer.comp';
import { NemChartToolbarComponent } from './components/nem-chart-toolbar/nem-chart-toolbar.comp';
import { NemTabularComponent } from './components/nem-tabular/nem-tabular.comp';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  SplitterModule,
  ColorPickerModule,
  SliderModule,
  SkeletonModule,
  TableModule,
  DynamicDialogModule,
  PanelModule,
  SelectButtonModule,
  SidebarModule,
  DialogModule,
  TooltipModule,
  OverlayPanelModule,
  ButtonModule,
  NgApexchartsModule,
  CheckboxModule,
  DropdownModule,
  ScrollPanelModule,
  MultiSelectModule
];

const components = [
  NemChartToolbarComponent,
  NemChartComponent,
  NemTabularComponent,
  NemShimmerComponent,
  NemChartMainComponent,
  NemPlotComponent,
  MainLayoutComponent
];

const pipes = [TruncatePipe, NumberFormatterPipe];

@NgModule({
  declarations: [...components, ...pipes],
  imports: [...modules],
  exports: [...modules, ...components, ...pipes]
})
export class NemChartModule {}
