import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'nem-copy',
  template: `
    <p-message
      class="info-message absolute top-[-20px] text-xs right-[60px] z-10"
      severity="info"
      text="Copied to clipboard"
      [class.show-up]="showMessage"></p-message>
    <div class="p-inputgroup">
      <button
        type="button"
        class=" p-button-outlined"
        label="Get link"
        pButton
        (click)="generateLink.emit(true)"></button>
      <input
        type="text"
        [(ngModel)]="textToCopy"
        class="rounded-none "
        [disabled]="true"
        pInputText
        placeholder="Share link" />
      <button
        type="button"
        class="w-[3rem] clipboard-button-icon p-button-outlined"
        icon="pi pi-clone"
        pButton
        (click)="copyToClipboard()"></button>
    </div>
  `,
  styles: [
    `
      :host {
        position: relative;
      }

      .info-message {
        transition: width 0.3s ease-in-out; /* Smooth animation for width */
        opacity: 0;
        transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
        transform: translateY(50%); /* Initial position */
        transform: translateX(10%); /* Initial position */
      }

      .info-message.show-up {
        opacity: 1;
        transform: translateX(0); /* Initial position */
        transform: translateY(0); /* Move content left when showing up */
      }

      .clipboard-button-icon {
        padding: 0 !important;
      }
    `
  ]
})
export class NemCopyComponent {
  @Input() textToCopy: string = '';

  @Output() generateLink = new EventEmitter();

  showMessage = false;

  private _timer: NodeJS.Timeout | undefined;

  constructor(private _clipboard: Clipboard) {}

  copyToClipboard() {
    this.showMessage = false;
    if (this._timer) {
      window.clearTimeout(this._timer);
    }
    this._clipboard.copy(this.textToCopy);
    this.showMessage = true;
    this._timer = setTimeout(() => {
      this.showMessage = false;
      window.clearTimeout(this._timer);
    }, 3000);
  }
}
