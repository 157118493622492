import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PortalModule } from '@angular/cdk/portal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';

import { BadgeModule } from 'primeng/badge';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { MessageModule } from 'primeng/message';

import { FiltersComponent } from '@components/filters/filters.comp';
import { TopMenuComponent } from '@components/top-menu/top-menu.comp';
import { YtVideoComponent } from '@components/yt-video/yt-video.comp';
import { NemDrawerComponent } from '@components/drawer/nem-drawer.comp';
import { NemCopyComponent } from '@components/copy/nem-copy.comp';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { RegionPipe } from '@pipes/region';

import { YouTubePlayerModule } from '@angular/youtube-player';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { NemLogoComponent } from '@components/v-nem-logo/v-nem-logo.com';

import { NemChartModule } from './nem-chart/nem-chart.module';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgApexchartsModule,
  PortalModule,
  DialogModule,
  MultiSelectModule,
  DropdownModule,
  CalendarModule,
  TooltipModule,
  OverlayPanelModule,
  TreeTableModule,
  TableModule,
  DynamicDialogModule,
  SkeletonModule,
  PanelModule,
  SelectButtonModule,
  SidebarModule,
  TabViewModule,
  ScrollPanelModule,
  CheckboxModule,
  InputSwitchModule,
  AmplifyAuthenticatorModule,
  YouTubePlayerModule,
  ConfirmDialogModule,
  BadgeModule,
  SplitterModule,
  ColorPickerModule,
  MessageModule,
  SliderModule,
  NemChartModule
];
const components = [
  TopMenuComponent,
  RegionPipe,
  YtVideoComponent,
  NemLogoComponent,
  FiltersComponent,
  NemDrawerComponent,
  NemCopyComponent
];

@NgModule({
  declarations: [...components],
  imports: [...modules, NgOptimizedImage],
  exports: [...modules, ...components],
  providers: [ConfirmationService]
})
export class SharedModule {}
