<p-dropdown
  [options]="chartTypeOptions"
  [(ngModel)]="chartTypeValue"
  (onChange)="chartTypeValueChange.emit(chartTypeValue)"
  optionLabel="name"
  optionValue="id"
  styleClass="w-[8rem] nem-dropdown-xs">
  <ng-template let-type pTemplate="item">
    <div class="flex gap-2 items-center">
      <span class="text-indigo-600 pi" [ngClass]="type.icon"></span>
      <div>{{ type.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
<button
  type="button"
  role="tab"
  (click)="focusModeChange.emit(true)"
  pTooltip="Active focus mode"
  tooltipPosition="top"
  class="mx-2 p-ripple p-element p-panel-header-icon p-link"
  aria-label="Active focus mode view">
  <span class="pi pi-tablet"></span>
</button>
<button
  type="button"
  role="tab"
  (click)="toggleTableViewChange.emit(true)"
  pTooltip="Show/Hide tabular view"
  tooltipPosition="top"
  class="mr-2 p-ripple p-element p-panel-header-icon p-link"
  aria-label="toggle tabular view">
  <span class="pi pi-table"></span>
</button>
<button
  type="button"
  role="tab"
  (click)="exportToCSVChange.emit(true)"
  pTooltip="Export CSV"
  tooltipPosition="top"
  class="mr-2 p-ripple p-element p-panel-header-icon p-link"
  aria-label="export button">
  <span class="pi pi-file-export"></span>
</button>
<button
  type="button"
  role="tab"
  (click)="dialogVisibleChange.emit(true)"
  pTooltip="Maximize panel"
  tooltipPosition="top"
  class="mr-2 p-ripple p-element p-panel-header-icon p-link"
  aria-label="expand button">
  <span class="pi pi-window-maximize"></span>
</button>
