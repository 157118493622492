import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, from, of, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private _authService: AuthService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._authService.isAdmin$.pipe(
      switchMap(isAdmin => {
        return isAdmin ? of(isAdmin) : from(this.router.navigate(['/']));
      }),
      catchError(err => {
        console.error(err);
        return from(this._authService.signOut().then(_ => this.router.navigate(['/'])));
      })
    );
  }
}
