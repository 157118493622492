import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Table } from 'primeng/table';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { Tabular } from '../../services/tabular';

@Component({
  selector: 'nem-tabular',
  templateUrl: './nem-tabular.comp.html',
  styles: [
    `
      .cell-style {
        border: 1px solid #ccc !important;
        text-align: right !important;
        padding: 0.2rem 0.5rem !important;
        transition: border-color 0.3s ease, background-color 0.3s ease;
      }

      .header-style {
        padding-right: 0 !important;
      }

      .highlight {
        background-color: #e4e4e7 !important;
      }

      .picker-size {
        width: 14px;
        height: 14px;
        border-radius: 4px;
        border-color: #ccc;
        border-width: 0;
      }
    `
  ]
})
export class NemTabularComponent implements OnInit, OnDestroy {
  @Input() tabular: Tabular | null = null;

  @Input() loading: boolean = false;

  @Input() dateFormat!: string;

  @ViewChild('table', { static: false }) table!: Table;

  @Input() syncScrolling!: boolean;

  @Output() syncScrollingChange = new EventEmitter<boolean>();

  private _destroy$ = new Subject();

  constructor(private cdr: ChangeDetectorRef, private _ngZone: NgZone) {}

  ngOnInit(): void {
    if (!this.tabular) return;
    this.tabular.xy$
      .pipe(
        takeUntil(this._destroy$),
        distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1])
      )
      .subscribe({
        next: ([rdx, _]) => {
          this._ngZone.run(() => {
            if (this.table && this.syncScrolling) {
              if (rdx !== -1) {
                const scroller = this.table.scroller;
                scroller.scrollToIndex(rdx, 'smooth');
              }

              this.cdr.detectChanges();
            }
          });
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
