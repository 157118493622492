import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { ATTRIBUTES } from '@constants/attributes';
import { FREQUENCY_PAYLOAD, INTERCONNECTORS_PAYLOAD, REGIONS_PAYLOAD } from '@constants/payloads';
import { FiltersService } from '@services/filters/filters.service';
import { map } from 'rxjs';
import { Service } from 'src/app/shared/registries/methods-services';
import { fixScroll } from '../../util';

// @ts-ignore
import DATE_RANGE_TEMPLATE from '@assets/tooltip-infos/date-range-info.html';
// @ts-ignore
import INTERCONNECTOR_INFO_TEMPLATE from '@assets/tooltip-infos/interconnectors-info.html';
// @ts-ignore
import NET_INTERCONNECTOR_INFO_TEMPLATE from '@assets/tooltip-infos/net-interconnectors-info.html';
// @ts-ignore
import STATIONS_DATE_TEMPLATE from '@assets/tooltip-infos/stations-date-info.html';

@Component({
  selector: 'nem-filters',
  templateUrl: './filters.comp.html'
})
export class FiltersComponent {
  constructor(public filterService: FiltersService, private _renderer: Renderer2) {}

  get filtersToShow() {
    return ATTRIBUTES[this.filterService.currentService];
  }

  get showNetInterconnectorsInfo() {
    return this.filterService.currentService === Service.REGIONAL;
  }

  @Input() showInterconnectorsInfo = true;

  @Input() showRangeInfo = true;

  @Input() loading: boolean = false;

  @Input() layoutDirection: 'vertical' | 'horizontal' = 'horizontal';

  @Output() filtersChange = this.filterService.query_fg.valueChanges;

  get isHorizontalLayout() {
    return this.layoutDirection === 'horizontal';
  }

  get filtersListClass(): NgClass['ngClass'] {
    return this.isHorizontalLayout
      ? 'flex items-center flex-wrap gap-x-3 gap-y-1'
      : 'flex flex-col  justify-items-stretch gap-y-2 mt-[1rem] pl-2 pr-4 pb-5';
  }

  get advancedClass() {
    return this.isHorizontalLayout ? '' : 'flex flex-col';
  }

  @Output() filtersOnSubmit = new EventEmitter<boolean>();

  stationOptions$ = this.filterService.stationResponse$?.pipe(map(res => res.stations));

  stationDates$ = this.filterService.stationResponse$?.pipe(map(res => res.stations_dates));

  stationsDateInfo = STATIONS_DATE_TEMPLATE;

  regions = REGIONS_PAYLOAD;

  interConnectors = INTERCONNECTORS_PAYLOAD;

  dataFrequency = FREQUENCY_PAYLOAD;

  dateRangeInfo = DATE_RANGE_TEMPLATE;

  interconnectorsInfo = INTERCONNECTOR_INFO_TEMPLATE;

  netInterconnectorsInfo = NET_INTERCONNECTOR_INFO_TEMPLATE;

  regionsOnChange() {
    this.filterService.updateOnRegionsChanges();
  }

  stationsOnChange({ originalEvent }: { originalEvent: Event }) {
    this.filterService.updateAggrByFuelSource();
    originalEvent.stopPropagation();
  }

  isSelected(name: string) {
    return this.filterService.isSelected(name);
  }

  filtersSubmit(ev: any) {
    if (ev) {
      this.filtersOnSubmit.emit(true);
    }
  }

  fixbodyscroll(evt: 'onshow' | 'onhide', name: string) {
    fixScroll(evt, this._renderer, name);
  }
}
