export enum MethodName {
  NET_INTERCONNECTOR = 'net-interconnector',
  DEMAND_DATA = 'demand-data',
  MIN_DEMAND_DATA = 'min-demand-data',
  MAX_DEMAND_DATA = 'max-demand-data',
  FUEL_MIX = 'fuel-mix',
  AVAILABILITY_DATA = 'availability-data',
  WHOLESALE_DATA = 'wholesale-data',
  METERED_FLOW = 'metered-flow',
  EXPORT_LIMITS = 'export-limits',
  IMPORT_LIMITS = 'import-limits',
  GENERATION = 'generation',
  PERCENT_DEMAND = 'percent_demand',
  PERCENT_USER_DEMAND = 'percent_user_demand',
  RESIDUAL_DEMAND = 'residual_demand',
  MAX_RESIDUAL_DEMAND = 'max_residual_demand',
  MIN_RESIDUAL_DEMAND = 'min_residual_demand',
  RENEWABLE_VWAP = 'renewable-vwap',
  GENERATORS_DISPATCH = 'generators-dispatch',
  MAX_GENERATION = 'maximum-generation',
  CAPACITY_FACTOR = 'capacity-factor',
  GENERATOR_AVAILABILITY = 'generator-availability',
  GENERATORS_VWAP = 'generators-vwap'
}

export const METHOD_LABEL_MAP = {
  [MethodName.NET_INTERCONNECTOR]: 'Regional Net Interconnector Flow',
  [MethodName.DEMAND_DATA]: 'Regional Average demand',
  [MethodName.MIN_DEMAND_DATA]: 'Regional Minimum demand',
  [MethodName.MAX_DEMAND_DATA]: 'Regional Maximum demand',
  [MethodName.FUEL_MIX]: 'Regional Fuel Mix',
  [MethodName.AVAILABILITY_DATA]: 'Average Regional Generation Capacity',
  [MethodName.WHOLESALE_DATA]: 'Regional Wholesale Price',
  [MethodName.METERED_FLOW]: 'Interconnectors Metered Flow',
  [MethodName.EXPORT_LIMITS]: 'Interconnectors Export Limits',
  [MethodName.IMPORT_LIMITS]: 'Interconnectors Import Limits',
  [MethodName.GENERATION]: 'Renewable Generation',
  [MethodName.PERCENT_DEMAND]: 'Percentage of Regional Demand',
  [MethodName.PERCENT_USER_DEMAND]: 'Percentage of Regional User Demand',
  [MethodName.RESIDUAL_DEMAND]: 'Residual Demand (demand minus renewable production)',
  [MethodName.MAX_RESIDUAL_DEMAND]: 'Maximum Residual Demand',
  [MethodName.MIN_RESIDUAL_DEMAND]: 'Minimum Residual Demand',
  [MethodName.RENEWABLE_VWAP]: 'Renewable Volume Weighted Average Price',
  [MethodName.GENERATORS_DISPATCH]: 'Generator Dispatch',
  [MethodName.MAX_GENERATION]: 'Generator Maximum Generation',
  [MethodName.CAPACITY_FACTOR]: 'Generator Capacity Factor',
  [MethodName.GENERATOR_AVAILABILITY]: 'Generator Availability',
  [MethodName.GENERATORS_VWAP]: 'Volume Weighted Average Price'
} as const;

export enum Service {
  INTERCONNECTOR = 'interconnectors',
  GENERATORS = 'generators',
  REGIONAL = 'regional',
  RENEWABLE = 'renewable',
  RENEWABLE_VWAP = 'renewable:vwap',
  GENERATORS_VWAP = 'generators:vwap'
}

export const SERVICE_METHODS: { [x: string]: MethodName[] } = {
  [Service.REGIONAL]: [
    MethodName.NET_INTERCONNECTOR,
    MethodName.DEMAND_DATA,
    MethodName.MIN_DEMAND_DATA,
    MethodName.MAX_DEMAND_DATA,
    MethodName.FUEL_MIX,
    MethodName.AVAILABILITY_DATA,
    MethodName.WHOLESALE_DATA
  ],
  [Service.RENEWABLE]: [
    MethodName.GENERATION,
    MethodName.PERCENT_DEMAND,
    MethodName.PERCENT_USER_DEMAND,
    MethodName.RESIDUAL_DEMAND,
    MethodName.MAX_RESIDUAL_DEMAND,
    MethodName.MIN_RESIDUAL_DEMAND
  ],
  [Service.INTERCONNECTOR]: [MethodName.METERED_FLOW, MethodName.EXPORT_LIMITS, MethodName.IMPORT_LIMITS],
  [Service.GENERATORS]: [
    MethodName.GENERATORS_DISPATCH,
    MethodName.MAX_GENERATION,
    MethodName.CAPACITY_FACTOR,
    MethodName.GENERATOR_AVAILABILITY
  ],
  [Service.GENERATORS_VWAP]: [MethodName.GENERATORS_VWAP],
  [Service.RENEWABLE_VWAP]: [MethodName.RENEWABLE_VWAP]
};

function getServiceLabel(name: MethodName | string | undefined | null) {
  return name ? METHOD_LABEL_MAP[name as MethodName] : '';
}

export const METHOD_NAMES = Object.entries(MethodName).map(entry => {
  return {
    label: entry[0],
    value: entry[1],
    text: getServiceLabel(entry[1]),
    disabled: entry[1] === MethodName.CAPACITY_FACTOR
  };
});

export function findService(methodName: string | undefined | null) {
  const service = Object.keys(SERVICE_METHODS).find(key => {
    return methodName && SERVICE_METHODS[key].includes(methodName as MethodName);
  });

  return (service || '') as Service;
}
