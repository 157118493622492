<div class="drawer-container">
  <div class="drawer" [class.collapsed]="isCollapsed">
    <div class="drawer-content">
      <ng-content *ngIf="!isCollapsed"></ng-content>
    </div>
  </div>

  <button
    type="button"
    (click)="toggleDrawer()"
    aria-label="drawer-button"
    [attr.title]="isCollapsed ? 'Show section' : 'Hide section'"
    [class.collapsed]="isCollapsed">
    <span
      class="pi text-xl"
      [ngClass]="{ 'pi-arrow-circle-right': isCollapsed, 'pi-arrow-circle-left': !isCollapsed }"></span>
  </button>
</div>
