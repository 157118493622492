import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FiltersArgs } from '@dtos/arguments.dto';
import { Frequency } from '@dtos/filters/frequency.dto';
import { StationDto, StationResponseDto } from '@dtos/filters/station.dto';
import { DEFAULT_RANGE, updateRange } from '@nemchart/services/date-formatter';
import { Service } from '@registries/methods-services';
import { BehaviorSubject, distinctUntilChanged, of, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { mapperToArgs, mapperToQueryFormGroup } from './filters';
import { generateId } from '../../util';

const BaseUrl = environment.api;

@Injectable()
export class FiltersService {
  private _ID = generateId();

  get skipCallStations() {
    return this.currentService !== Service.GENERATORS;
  }

  get skipCallFuelSources() {
    return this.currentService !== Service.GENERATORS && this.currentService !== Service.GENERATORS_VWAP;
  }

  get regions() {
    return this.query_fg.get('regions');
  }

  get fuel_sources() {
    return this.query_fg.get('fuel_sources');
  }

  get stations() {
    return this.query_fg.get('stations');
  }

  query_fg = this._fb.nonNullable.group({
    frequency: [Frequency.DAILY],
    range_from: [DEFAULT_RANGE[0], Validators.required],
    range_to: [DEFAULT_RANGE[1], Validators.required],
    region: ['VIC'],
    regions: [['VIC']],
    interconnectors: [['NSW1-QLD1']],
    stations: [[] as StationDto[]],
    aggr_by_fuel_sources: [true],
    fuel_sources: [[] as string[]]
  });

  currentService: Service = Service.REGIONAL;

  args: BehaviorSubject<FiltersArgs> = new BehaviorSubject(mapperToArgs(this.query_fg.value, this.currentService));

  serviceVisited: Record<string, BehaviorSubject<FiltersArgs>> = {};

  fuelSourcesOptions$ = this.regions?.valueChanges
    .pipe(
      startWith(this.regions.value),
      distinctUntilChanged((a, b) => a === b),
      switchMap(regions => {
        return regions?.length && !this.skipCallFuelSources
          ? this._filterQuery({
              regions: regions
            })
          : of([]);
      })
    )
    .pipe(shareReplay());

  stationResponse$ = this.fuel_sources?.valueChanges
    .pipe(
      distinctUntilChanged((a, b) => a === b),
      switchMap(fuelSources => {
        return !this.skipCallStations && fuelSources?.length && this.regions?.value
          ? this._filterQuery<StationResponseDto>({
              regions: this.regions.value,
              fuel_sources: fuelSources
            })
          : of({ stations: [] as StationDto[], stations_dates: null });
      })
    )
    .pipe(shareReplay());

  constructor(private _http: HttpClient, private _fb: FormBuilder) {
    console.log(this._ID);

    this.query_fg.valueChanges
      .pipe(
        tap(({ range_from, range_to }) => {
          this._updateRange(range_from, range_to);
        })
      )
      .subscribe({
        next: value => {
          this.args.next(mapperToArgs(value, this.currentService));
        }
      });

    this.stations?.valueChanges.subscribe({
      next: _ => {
        this.updateAggrByFuelSource();
      }
    });
  }

  initQuery(service: Service) {
    this.currentService = service;

    if (this.serviceVisited[service]) {
      this.query_fg.patchValue({
        ...mapperToQueryFormGroup(this.serviceVisited[service].getValue())
      });

      return;
    } else {
      this.args.next(mapperToArgs(this.query_fg.value, this.currentService));
      this.serviceVisited[service] = this.args;
    }
  }

  patchQuery(value: Partial<FiltersArgs & { range_from: string; range_to: string }>) {
    this.query_fg.patchValue({ ...value });
  }

  isSelected(name: string) {
    return this.stations?.value ? this.stations?.value.map(st => st.name).indexOf(name) > -1 : false;
  }

  updateOnRegionsChanges() {
    this.query_fg.patchValue({
      fuel_sources: [],
      stations: []
    });
  }

  updateAggrByFuelSource() {
    if (this.stations?.value) {
      const allGrouped = this.stations.value.length > 0 && this.stations.value.every(st => st.grouped);
      this.query_fg.patchValue({
        aggr_by_fuel_sources: allGrouped
      });
    }
  }

  private _filterQuery<T = string[]>(body: { regions: string[]; fuel_sources?: string[] }) {
    return this._http
      .post<T>(`${BaseUrl}/nem-generators/stations`, {
        regions: body.regions,
        fuel_sources: body.fuel_sources
      })
      .pipe(distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)));
  }

  private _updateRange(range_from: string | undefined, range_to: string | undefined) {
    if (range_from && range_to) {
      const range = updateRange([range_from, range_to]);

      this.query_fg.patchValue(
        {
          range_from: range[0] as string,
          range_to: range[1] as string
        },
        { emitEvent: false }
      );
    }
  }
}
