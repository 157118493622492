import { Service } from '../registries/methods-services';

export const ATTRIBUTES = {
  [Service.INTERCONNECTOR]: 'interconnectors,frequency,range',
  [Service.GENERATORS]: 'regions,frequency,range,advanced:full',
  [Service.REGIONAL]: 'regions,frequency,range',
  [Service.RENEWABLE]: 'single_region,frequency,range',
  [Service.RENEWABLE_VWAP]: 'single_region,frequency,range',
  [Service.GENERATORS_VWAP]: 'regions,frequency,range,advanced'
};
