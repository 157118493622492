export enum RoutePath {
  base = '',
  interconnectors = 'interconnectors',
  generators = 'nem-generators',
  vwap = 'nem-vwap',
  renewable = 'renewable-summary',
  regional = 'regional',
  tasks = 'tasks',
  onDemand = 'on-demand',
  settings = 'settings',
  focusmode = 'focus-mode/:id/:method',
  paperEditor = 'paper-editor',
  wildcard = '**'
}

export const NAVIGATION = [
  { label: 'About', routerLink: RoutePath.base, tooltip: '' },
  { label: 'Regional NEM', routerLink: RoutePath.regional, tooltip: '' },
  { label: 'NEM Generators', routerLink: RoutePath.generators, tooltip: '' },
  { label: 'NEM Vwap', routerLink: RoutePath.vwap, tooltip: 'Volume weighted average price' },
  { label: 'Renewable Summary', routerLink: RoutePath.renewable, tooltip: '' },
  {
    label: 'Interconnectors',
    routerLink: RoutePath.interconnectors,
    tooltip: ''
  },
  { label: 'Scraper tasks', routerLink: RoutePath.tasks, tooltip: '', groups: ['admin'] },
  { label: 'On Demand data', routerLink: RoutePath.onDemand, tooltip: '', groups: ['admin'] },
  { label: 'Settings', routerLink: RoutePath.settings, tooltip: '', groups: ['admin'] }
];
