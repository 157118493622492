import { Component, HostBinding, OnInit } from '@angular/core';

const VIDEOS_LIST = 'sgxcjDKPAgg,JMN39w4qebI,6bjz1zltRt4,NF_xLa1Sl7I,gXGhUstlsEA,oBY4KX_ebJ8';
const VIDEOS_LIST_NAMES =
  'V-NEM Introduction,Regional NEM,NEM Generators Tab,NEM VWAP (Volume Weighted Average Price),Renewables summary,Interconnector Data';
const VIDEOS = VIDEOS_LIST.split(',').map((id, index) => {
  return {
    id,
    name: VIDEOS_LIST_NAMES.split(',')[index]
  };
});

@Component({
  template: `
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div class="">
        <h2 class="mb-4 text-center">The following URLs introduce you to the dashboard through YouTube clips</h2>
        <ul role="list" class="divide-y flex-1 divide-gray-100 rounded-md border border-gray-200 my-6">
          <li
            *ngFor="let vd of videos"
            (click)="play(vd.id)"
            [ngClass]="{ 'font-extrabold': selectedId === vd.id }"
            class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6 cursor-pointer">
            <div class="flex w-0 flex-1 items-center">
              <div class="ml-4 flex min-w-0 flex-1 gap-2">
                <span class="truncate font-medium" [ngClass]="{ 'font-extrabold': selectedId === vd.id }">
                  {{ vd.name }}</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-span-2">
        <youtube-player [playerVars]="playerVars" suggestedQuality="hd720" [videoId]="selectedId"></youtube-player>
      </div>
    </div>
  `,
  selector: 'nem-yt-video'
})
export class YtVideoComponent implements OnInit {
  @HostBinding('class') classname = 'card h-full pt-3';

  playerVars = {
    playlist: VIDEOS_LIST,
    enablejsapi: 1
  } as YT.PlayerVars;

  videos = VIDEOS;

  selectedId = VIDEOS[0].id;

  play(id: string) {
    this.selectedId = id;
  }

  ngOnInit() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }
}
