import { FiltersArgs } from '@dtos/arguments.dto';
import { CommonArgs } from '@dtos/common-args.dto';
import { Frequency } from '@dtos/filters/frequency.dto';
import { StationDto } from '@dtos/filters/station.dto';
import { Service } from '@registries/methods-services';

export class FiltersArgsModel {
  frequency: Frequency;
  regions: string[];
  interconnectors: string[];
  stations: StationDto[];
  aggr_by_fuel_sources: boolean;
  fuel_sources: string[];
  region: string;
  range_from: string;
  range_to: string;

  constructor(private _partial: Partial<FiltersArgsModel>) {
    const p = this._partial;

    this.frequency = p.frequency ? p.frequency : Frequency.DAILY;
    this.regions = p.regions ? p.regions : [];
    this.interconnectors = p.interconnectors ? p.interconnectors : [];
    this.stations = p.stations ? p.stations : ([] as StationDto[]);
    this.aggr_by_fuel_sources = p.aggr_by_fuel_sources !== undefined ? p.aggr_by_fuel_sources : false;
    this.fuel_sources = p.fuel_sources ? p.fuel_sources : [];
    this.region = p.region ? p.region : '';
    this.range_from = p.range_from ? p.range_from : '';
    this.range_to = p.range_to ? p.range_to : '';
  }
}

export function mapperToArgs(value: Partial<FiltersArgsModel>, service: Service) {
  const common: CommonArgs = {
    frequency: value.frequency ? value.frequency : Frequency.DAILY,
    range: [value.range_from ? value.range_from : '', value.range_to ? value.range_to : '']
  };

  switch (service) {
    case Service.GENERATORS:
      return {
        ...common,
        regions: value.regions ? value.regions : [],
        fuel_sources: value.fuel_sources ? value.fuel_sources : [],
        stations: value.stations ? value.stations : ([] as StationDto[]),
        aggr_by_fuel_sources: value.aggr_by_fuel_sources !== undefined ? value.aggr_by_fuel_sources : false
      } as FiltersArgs;
    case Service.GENERATORS_VWAP:
      return {
        ...common,
        regions: value.regions ? value.regions : [],
        fuel_sources: value.fuel_sources ? value.fuel_sources : []
      } as FiltersArgs;

    case Service.REGIONAL:
      return {
        ...common,
        regions: value.regions ? value.regions : []
      } as FiltersArgs;

    case Service.RENEWABLE:
    case Service.RENEWABLE_VWAP:
      return {
        ...common,
        region: value.region ? value.region : ''
      } as FiltersArgs;

    case Service.INTERCONNECTOR:
      return {
        ...common,
        interconnectors: value.interconnectors ? value.interconnectors : []
      } as FiltersArgs;
  }
}

export function mapperToQueryFormGroup(value: FiltersArgs) {
  return {
    range_from: value.range[0],
    range_to: value.range[1],
    frequency: value.frequency,
    ...(value?.regions ? { regions: value?.regions } : {}),
    ...(value.interconnectors ? { interconnectors: value.interconnectors } : {}),
    ...(value?.stations ? { stations: value?.stations } : {}),
    ...(value?.aggr_by_fuel_sources ? { aggr_by_fuel_sources: value?.aggr_by_fuel_sources } : {}),
    ...(value?.fuel_sources ? { fuel_sources: value?.fuel_sources } : {}),
    ...(value?.region ? { region: value?.region } : {})
  };
}
