<ng-container *ngIf="previewMode; else mainTemp">
  <div
    class="relative"
    *ngIf="
      nemChart && !dialogVisible && !resizing && !nemChart.loading && (nemChart.value$ | async) as value;
      else loaderTmp
    ">
    <div class="absolute top-[-1.25rem] right-0">
      <div class="logo-placeholder"></div>
    </div>
    <nem-plot [options]="value.options" [hideLegend]="nemChart.hideLegend"> </nem-plot>
    <nem-plot [options]="nemChart.brush$ | async"> </nem-plot>
    <div class="flex items-center justify-center">
      <span class="font-semibold text-center" *ngIf="nemChart.value$ | async as value">
        {{ value.options?.title?.text }} - {{ nemChart.titleFrequency$ | async }}
        {{ nemChart.titleRegion$ | async }}
      </span>
    </div>
  </div>
</ng-container>

<ng-template #mainTemp>
  <nem-main>
    <p-panel [toggleable]="true" styleClass="nem-chart">
      <!-- Layout without tabular -->
      <ng-template pTemplate="header">
        <span class="panel-title" *ngIf="nemChart && nemChart.value$ | async as value">
          {{ value.options?.title?.text }} - {{ nemChart?.titleFrequency$ | async }}
          {{ nemChart?.titleRegion$ | async }}
        </span>
        <div class="logo-placeholder"></div>
      </ng-template>

      <ng-template pTemplate="icons">
        <nem-chart-toolbar
          [chartTypeValue]="nemChart && nemChart.chartType"
          (chartTypeValueChange)="chartTypeChange($event)"
          (toggleTableViewChange)="toggleTableView()"
          (exportToCSVChange)="nemChart && nemChart.exportToCSV()"
          (focusModeChange)="redirectToFocusmode()"
          (dialogVisibleChange)="dialogVisible = $event"></nem-chart-toolbar>
      </ng-template>

      <div class="flex items-stretch gap-4">
        <ng-content></ng-content>
        <div class="flex-1">
          <ng-container *ngIf="nemChart && !nemChart.tabular.tabularView">
            <ng-container *ngIf="!resizing && !nemChart.loading && (nemChart.value$ | async) as value; else loaderTmp">
              <div class="w-full p-2" *ngIf="!dialogVisible">
                <nem-plot [options]="value.options" [hideLegend]="nemChart.hideLegend"> </nem-plot>
                <nem-plot [options]="nemChart.brush$ | async"> </nem-plot>
              </div>
            </ng-container>
          </ng-container>

          <!-- Layout with splitter and toggable tabular  -->
          <ng-container *ngIf="nemChart && nemChart.tabular.tabularView">
            <p-splitter
              styleClass="min-h-[450px] w-full"
              [panelSizes]="nemChart ? nemChart.tabular.panelSizes : []"
              (onResizeStart)="onResizeStart()"
              (onResizeEnd)="onResizeEnd()"
              [layout]="'horizontal'">
              <ng-template pTemplate>
                <div class="w-full m-auto">
                  <div
                    class="p-2"
                    *ngIf="
                      nemChart &&
                        !dialogVisible &&
                        !resizing &&
                        !nemChart.loading &&
                        (nemChart.value$ | async) as value;
                      else loaderTmp
                    ">
                    <nem-plot [options]="value.options" [hideLegend]="nemChart.hideLegend"> </nem-plot>
                    <nem-plot [options]="nemChart.brush$ | async"> </nem-plot>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate>
                <nem-tabular
                  class="w-full"
                  *ngIf="nemChart"
                  [loading]="nemChart.loading"
                  [tabular]="nemChart.tabular"
                  [(syncScrolling)]="nemChart.syncWithTabularScrolling"
                  [dateFormat]="nemChart.dateFormat"></nem-tabular>
              </ng-template>
            </p-splitter>
          </ng-container>
        </div>
      </div>
    </p-panel>
  </nem-main>
</ng-template>
<!-- Layout when dialog is maximized  -->
<ng-container *ngIf="nemChart && dialogVisible && (nemChart.value$ | async) as value">
  <p-dialog
    [modal]="true"
    [attr.id]="nemChart.containerId"
    (onResizeEnd)="refresh()"
    (onMaximize)="refresh()"
    [(visible)]="dialogVisible"
    [maximizable]="false"
    [style]="{ width: '80vw' }">
    <ng-template pTemplate="header">
      <span>
        <span class="p-panel-header-icon capture-remove">
          <p-button
            styleClass="mr-2 p-button-rounded p-button-text snapshot-button"
            pTooltip="Capture snapshot"
            tooltipPosition="top"
            icon="pi pi-clone"
            [disabled]="capturePending"
            [loading]="capturePending"
            (onClick)="takeSnapshot(value.options?.title?.text, nemChart.containerId)">
          </p-button>
        </span>

        <span class="panel-title">
          {{ value.options?.title?.text }} - {{ nemChart.titleFrequency$ | async }} {{ nemChart.titleRegion$ | async }}
        </span>
      </span>
      <div class="logo-placeholder logo-placeholder-in-dialog right-[8rem]"></div>
    </ng-template>

    <nem-plot [options]="value.options" [hideLegend]="nemChart.hideLegend"> </nem-plot>
  </p-dialog>
</ng-container>

<!-- Template for loading  -->
<ng-template #loaderTmp>
  <div class="p-2">
    <nem-shimmer [text]="resizing ? 'Rendering' : 'Loading data'"></nem-shimmer>
  </div>
</ng-template>
